<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2023-08-18 15:07 -->
<!-- Description:  -->
<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="mb-2">
          <button
            class="btn btn-primary mr-1"
            type="button"
            @click="
              $refs.elModalForm.show();
              $refs.elPlanForm.reset();
            "
          >
            Nuevo presupuesto
          </button>
          <button
            class="btn btn-primary mr-1"
            type="button"
            @click="$refs.elModalSearch.show()"
          >
            Buscar cliente
          </button>
          <a
            class="btn btn-danger mr-1"
            target="_blank"
            :href="makeCurrentReport"
          >
            <i class="fas fa-file-pdf"></i> Reporte Diario
          </a>
          <a
            class="btn btn-danger mr-1"
            target="_blank"
            :href="makeMonthReport"
          >
            <i class="fas fa-file-pdf"></i> Reporte mensual
          </a>
        </div>

        <div class="row mb-3">
          <div class="col-lg-4 col-12">
            <input
              type="date"
              class="form-control"
              style="width: 200px"
              v-model="operation_at"
              @change="$refs.elTableViewSet.reload()"
            />
          </div>
          <div class="col-lg-8 col-12">
            {{ dynamicDate }}
          </div>
        </div>

        <TableViewSet
          :getter="PlanService.getList"
          ref="elTableViewSet"
          :params="{ operation_at: operation_at, entity_id: entityId }"
        >
          <template #default="{ list }">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Nombre</th>
                <th scope="col">Celular</th>
                <th>Cat.</th>
                <th scope="col">Asunto</th>
                <th scope="col" class="text-right">Precio<br>Publico</th>
                <th scope="col" class="text-right">Precio<br>Interno</th>
                <th class="text-right">Ganancia</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in list" :key="i">
                <td>{{ item.id }}</td>
                <td>{{ item.client_name }}</td>
                <td>{{ item.client_phone }}</td>
                <td>{{ item.categorie_name }}</td>
                <td>{{ item.issue }}</td>
                <td class="text-right">
                  <app-span-money
                    :quantity="item.total_price"
                    :moneyCode="1"
                  ></app-span-money>
                </td>
                <td class="text-right">
                  <app-span-money
                    :quantity="item.total_cost"
                    :moneyCode="1"
                  ></app-span-money>
                </td>
                <td class="text-right">
                  <app-span-money
                    :quantity="item.total_paid - item.total_cost"
                    :moneyCode="1"
                  ></app-span-money>
                </td>
                <td>
                  <button class="btn btn-primary" @click="editRow(item)">
                    <i class="fas fa-edit"></i>
                  </button>
                  <button class="btn btn-primary" @click="deleteRow(item)">
                    <i class="fas fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </template>
        </TableViewSet>
      </div>
    </div>

    <app-modal-basic ref="elModalForm" :lg="true">
      <PlanForm
        ref="elPlanForm"
        @submitted="
          $refs.elModalForm.hide();
          $refs.elTableViewSet.reload();
        "
        :operation_at="operation_at"
        :entity_id="entityId"
      ></PlanForm>
    </app-modal-basic>

    <app-modal-basic ref="elModalSearch">
      <SearchForm @see="seeSomeDate($event)"></SearchForm>
    </app-modal-basic>
  </div>
</template>
<script>
import PlanService from "./PlanService.js";
import TableViewSet from "src/components/TableViewSet.vue";
import { AdminService } from "src/lt/admin-module/AdminService.js";
import PlanForm from "./PlanForm.vue";
import SearchForm from "./SearchForm.vue";
import myMoment from "src/lt/utils/myMoment.js";
export default {
  name: "ServicePlanDaily",
  components: {
    TableViewSet,
    PlanForm,
    SearchForm,
  },

  // directives

  props: {
    entityId: {
      // required: true,
    },
  },

  data: () => ({
    PlanService,
    AdminService,
    operation_at: null,
  }),

  computed: {
    makeMonthReport() {
      return (
        `${process.env.VUE_APP_API_URL}` +
        `service/report/client140/service-month?date=${this.operation_at}&entity_id=${this.entityId}`
      );
    },
    makeCurrentReport() {
      return (
        `${process.env.VUE_APP_API_URL}` +
        `service/report/client140/service-daily?date=${this.operation_at}&entity_id=${this.entityId}`
      );
    },
    dynamicDate() {
      if (this.operation_at != null) {
        let calendarTime = myMoment(this.operation_at).calendar();
        return calendarTime.replace(" a las 0:00", "").toUpperCase();
      }
      return "";
    },
  },

  // watch: {},

  created() {
    let currentDate = new Date();
    this.operation_at =
      currentDate.getFullYear() +
      "-" +
      (currentDate.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      currentDate.getDate();
  },

  mounted() {
    //
  },

  methods: {
    async editRow(item) {
      var row = await PlanService.get(item.id);
      this.$refs.elPlanForm.setValue(row);
      this.$refs.elModalForm.show();
    },
    seeSomeDate(ev) {
      this.operation_at = ev.operation_at;
      this.$refs.elModalSearch.hide();
      setTimeout(() => {
        this.$refs.elTableViewSet.reload();
      }, 200);
    },
    async deleteRow(item) {
      if (window.confirm("Eliminar este registro?")) {
        await PlanService.delete(item.id);
        this.$refs.elTableViewSet.reload();
      }
    },
    //
    //
  },
};
</script>
<style scoped></style>
