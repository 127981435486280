<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2023-08-18 17:37 -->
<!-- Description:  -->
<template>
  <ul
    role="menubar"
    aria-disabled="false"
    aria-label="Pagination"
    class="pagination b-pagination"
  >
    <li role="presentation" aria-hidden="true" class="page-item">
      <button
        role="menuitem"
        aria-label="Go to first page"
        @click="setModel(1)"
        aria-disabled="true"
        class="page-link"
        v-if="this.total > this.maxAxisLimit"
      >
        &laquo;
      </button>
    </li>
    <li role="presentation" aria-hidden="true" class="page-item">
      <button
        role="menuitem"
        aria-label="Go to previous page"
        aria-disabled="true"
        class="page-link"
        @click="setModel(modelValue - 1)"
        v-if="this.total > this.maxAxisLimit"
      >
        &lsaquo;
      </button>
    </li>
    <li
      role="presentation"
      class="page-item"
      v-for="page in listPages"
      :key="page"
      :class="{ active: page == modelValue }"
    >
      <button
        role="menuitemradio"
        type="button"
        aria-label="Go to page 2"
        aria-checked="false"
        aria-posinset="2"
        @click="setModel(page)"
        aria-setsize="26"
        tabindex="-1"
        class="page-link"
      >
        {{ page }}
      </button>
    </li>

    <!-- <li role="separator" class="page-item disabled bv-d-xs-down-none"> -->
    <!--   <span class="page-link">…</span> -->
    <!-- </li> -->
    <li role="presentation" class="page-item">
      <button
        role="menuitem"
        type="button"
        tabindex="-1"
        aria-label="Go to next page"
        class="page-link"
        @click="setModel(modelValue + 1)"
        v-if="this.total > this.maxAxisLimit"
      >
        &rsaquo;
      </button>
    </li>
    <li role="presentation" class="page-item">
      <button
        role="menuitem"
        type="button"
        tabindex="-1"
        aria-label="Go to last page"
        class="page-link"
        @click="setModel(total)"
        v-if="this.total > this.maxAxisLimit"
      >
        &raquo;
      </button>
    </li>
  </ul>
</template>
<script>
// script content
export default {
  name: "Paginate",
  components: {
    //
  },

  // directives

  props: {
    total: { default: 0, required: true },
    modelValue: { default: 1, required: true }
  },

  data: () => ({
    minAxisLimit: 2,
    maxAxisLimit: 5
  }),

  computed: {
    listPages() {
      if (this.total === 0 || this.modelValue <= 0) {
        return [1];
      }
      var currList = [];
      if (this.modelValue <= this.minAxisLimit) {
        var currTotal =
          this.total < this.maxAxisLimit ? this.total : this.maxAxisLimit;
        return Array.from(Array(currTotal), (x, i) => i + 1);
      } else if (this.modelValue >= this.total - this.minAxisLimit) {
        for (var i = this.total - this.maxAxisLimit; i <= this.total; i++) {
          currList.push(i);
        }
        return currList;
      } else {
        currList.push(this.modelValue);
        for (var j = 1; j <= this.minAxisLimit; j++) {
          currList.push(this.modelValue + j);
          currList.unshift(this.modelValue - j);
        }
        return currList;
      }

      // return [1, 2, 3];
    }
  },

  // watch: {},

  mounted() {
    //
  },

  methods: {
    setModel(val) {
      this.$emit("modelValue:update", val);
    }
    //
    //
  }
};
</script>
<style scoped></style>
