<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2023-08-18 17:36 -->
<!-- Description:  -->
<template>
  <div>
    <table class="table">
      <slot :list="list"></slot>
      <!-- <thead> -->
      <!--   <slot name="thead"></slot> -->
      <!-- </thead> -->
      <!-- <tbody> -->
      <!--   <slot name="tbody" :list="list"> -->
      <!--     <tr v-for="(item, i) in list" :key="i"> -->
      <!--       <slot name="tr" :item="item"></slot> -->
      <!--     </tr> -->
      <!--   </slot> -->
      <!-- </tbody> -->
    </table>
    <Paginate
      :total="total"
      :modelValue="page"
      @modelValue:update="
        page = $event;
        reload();
      "
    ></Paginate>
  </div>
</template>
<script>
// script content
import Paginate from "./Paginate.vue";
export default {
  name: "TableViewSet",
  components: {
    Paginate
  },

  // directives

  props: {
    getter: { required: true },
    params: { default: () => {} },
    loadOnMounted: { default: true },
  },

  data: () => ({
    list: [],
    total: 0,
    page: 1,
  }),

  computed: {
    //
  },

  // watch: {},

  mounted() {
    if(this.loadOnMounted) {
      this.reload();
    }
  },

  methods: {
    async reload() {
      var res = await this.getter({
        ...this.params,
        page: this.page,
      });
      this.list = res.data;
      this.total = res.last_page;
      this.page = res.current_page;
    }
    //
    //
  }
};
</script>
<style scoped></style>
