<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2023-08-19 13:53 -->
<!-- Description: Formulario simple insertable -->
<template>
  <form class="row" @submit.prevent="save" name="simple-form-sale">
    <div class="form-group col-12">
      <label for="">DNI Cliente</label>
      <input
        class="form-control"
        type="text"
        step="1"
        maxlength="8"
        v-model="reg.idcard"
        @keyup.enter="searchClient()"
      />
      <app-form-errors :errors="errors.idcard"></app-form-errors>
    </div>
    <div class="col-12" v-show="searching">
      <app-loading>Buscando ...</app-loading>
    </div>
    <div class="form-group col-12">
      <label for="">Nombre Completo</label>
      <input 
        class="form-control" 
        type="text" 
        maxlength="255" 
        v-model="reg.name" />
      <app-form-errors :errors="errors.name"></app-form-errors>
    </div>
    <div class="form-group col-12">
      <label for="">Celular</label>
      <input
        class="form-control"
        type="text"
        step="1"
        v-model="reg.cell_phone_number"
      />
      <app-form-errors :errors="errors.cell_phone_number"></app-form-errors>
    </div>
  </form>
</template>
<script>
import { GeneralService } from "src/lt/general-module/GeneralService.js";
import { StoreService } from "src/lt/store-module/StoreService.js";
import validator from "src/lt/utils/validator.js";

const rules = {
  idcard: { presence: true },
  name: { presence: true },
  cell_phone_number: { presence: true, length: { maximum: 9 } },
};
export default {
  name: "ClientMiniForm",
  components: {
    //
  },

  // directives

  props: {
    disabled: { default: false }
  },

  data: () => ({
    reg: {},
    searching: false,
    sending: false
  }),

  computed: {
    errors() {
      return validator(this.reg, rules);
    }
  },

  // watch: {},

  mounted() {
    //
  },

  methods: {
    async save() {
      this.sending = true;
      var res = await StoreService.saveClient(this.reg);
      this.$emit("submitted", res);
      return res;
    },
    reset() {
      this.sending = false;
    },
    setValue(reg) {
      this.reg = reg;
    },
    async searchClient() {
      this.searching = true;
      try {
        let result = await StoreService.getClients({
          search: this.reg.idcard
        });
        if (result.data.length > 0) {
          this.reg = result.data[0];
        } else {
          let idcard = this.reg.idcard;
          let client = await GeneralService.searchDni(idcard);
          this.reg = {};
          this.reg.id = null;
          this.reg.idcard = idcard;
          this.reg.name = client.apellidos_nombres;
        }
      } catch (e) {
        // alert("algo salio mal :/");
      }
      this.searching = false;
    },
  }
};
</script>
<style scoped></style>
