<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2023-09-01 09:42 -->
<!-- Description:  -->
<template>
  <div>
    <ServicePlanDaily :entityId="entityId"></ServicePlanDaily>
  </div>
</template>
<script>
// script content
import ServicePlanDaily from "./ServicePlanDaily.vue";
export default {
  name: "Container",
  components: {
    ServicePlanDaily,
  },

  // directives

  props: {
    //
  },

  data: () => ({
    //
  }),

  computed: {
    entityId() {
      return this.$store.state.user.entity_id;
    }
  },

  // watch: {},

  mounted() {
    //
  },

  methods: {
    //
    //
    //
  }
};
</script>
<style scoped></style>

