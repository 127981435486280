<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2023-08-18 22:34 -->
<!-- Description:  -->
<template>
  <form @submit.prevent="save()">
    <div class="row">
      <div class="col">
        <ClientMiniForm ref="elClientMiniForm"></ClientMiniForm>
        <div class="mb-3">
          <label for="" class="form-label"> Asunto </label>
          <input
            type="text"
            class="form-control"
            list="planFormAsuntoDatalist"
            placeholder=""
            v-model="reg.issue"
          />
          <datalist id="planFormAsuntoDatalist">
            <option value="NUEVO"></option>
            <option value="RECATEGORIZACION"></option>
            <option value="REVALIDACION"></option>
            <option value="OBTENCION"></option>
          </datalist>
        </div>
        <div class="mb-3">
          <label for="" class="form-label"> Categoria </label>
          <CategoriaInputSelect
            :value="reg.categorie_name"
            @input="
              reg.categorie_id = null;
              reg.categorie_name = $event;
            "
          ></CategoriaInputSelect>
        </div>
      </div>
      <div class="col-12">
        <legend>Productos</legend>
        <table class="table table-sm">
          <thead>
            <tr>
              <th>Descripcion</th>
              <th v-if="registrarPrecioPublico">Precio<br>Publico</th>
              <th v-if="registrarPagado">Pagado</th>
              <th>Precio<br>Interno</th>
              <th>Comentario</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in details" :key="i">
              <td>
                <app-checkbox v-model="item.enabled">
                  {{ item.name }}
                </app-checkbox>
              </td>
              <td v-if="registrarPrecioPublico">
                <input
                  type="number"
                  step="0.01"
                  class="form-control form-control-sm text-center"
                  style="width: 6rem"
                  id="exampleFormControlInput1"
                  v-model.number="item.price"
                  placeholder=""
                />
              </td>
              <td v-if="registrarPagado">
                <input
                  type="number"
                  step="0.01"
                  class="form-control form-control-sm text-center"
                  style="width: 6rem"
                  id="exampleFormControlInput1"
                  v-model.number="item.paid"
                  placeholder=""
                />
              </td>
              <td>
                <input
                  type="number"
                  step="0.01"
                  class="form-control form-control-sm text-center"
                  style="width: 6rem"
                  id="exampleFormControlInput1"
                  v-model.number="item.cost"
                  placeholder=""
                />
              </td>
              <td>
                <input
                  v-show="item.enabled"
                  type="text"
                  step="0.01"
                  maxlength="200"
                  class="form-control form-control-sm text-center"
                  style="width: 12rem"
                  id="planDetailFormComment"
                  v-model="item.comment"
                  placeholder=""
                />
              </td>
            </tr>
            <tr class="fw-medium">
              <td></td>
              <td v-if="registrarPrecioPublico">
                <app-span-money
                  :quantity="total"
                  :moneyCode="1"
                ></app-span-money>
              </td>
              <td v-if="registrarPagado">
                <app-span-money
                  :quantity="total_paid"
                  :moneyCode="1"
                ></app-span-money>
              </td>
              <td>
                <app-span-money
                  :quantity="total_cost"
                  :moneyCode="1"
                ></app-span-money>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <button class="btn btn-primary" type="submit">Guardar</button>
  </form>
</template>
<script>
import { StoreService } from "src/lt/store-module/StoreService.js";
import ClientMiniForm from "./clients/ClientMiniForm.vue";
import PlanService from "./PlanService.js";
import CategoriaInputSelect from "src/lt/general-module/categories/InputSelect.vue";
const PLAN_PRODUCT_GROUP = "scmod";
export default {
  name: "PlanForm",
  components: {
    ClientMiniForm,
    CategoriaInputSelect,
  },

  // directives

  props: {
    disabled: { default: false },
    operation_at: { default: null },
    entity_id: { default: null },
  },

  data: () => ({
    reg: {},
    details: [],
    sending: false,
    registrarPrecioPublico: true,
    registrarPagado: false,
  }),

  computed: {
    errors() {
      return false;
    },
    total() {
      return this.details.reduce((p, c) => {
        return c.enabled ? p + c.price : p;
      }, 0);
    },
    total_paid() {
      return this.details.reduce((p, c) => {
        return c.enabled ? p + c.paid : p;
      }, 0);
    },
    total_cost() {
      return this.details.reduce((p, c) => {
        return c.enabled ? p + c.cost : p;
      }, 0);
    },
  },

  // watch: {},

  mounted() {
    //
  },

  methods: {
    async save() {
      if(!this.registrarPagado) {
        this.details = this.details.map((x) => {
          x.paid = x.price;
          return x;
        });
      }

      this.sending = true;
      var client = await this.$refs.elClientMiniForm.save();
      this.reg.operation_at = this.operation_at;
      this.reg.entity_id = this.entity_id;
      this.reg.client_id = client.id; // agregamos client_id
      this.reg.details = this.details;
      var res = await PlanService.save(this.reg);
      this.$emit("submitted");
      return res;
    },
    reset() {
      this.reg = {};
      this.$refs.elClientMiniForm.reset();
      this.sending = false;
      this.getListProducts();
    },
    setValue(reg) {
      this.reg = reg;
      this.details = reg.details;
      this.$refs.elClientMiniForm.setValue(reg.client);
    },
    async getListProducts() {
      var res = await StoreService.getProducts({
        group_label: PLAN_PRODUCT_GROUP,
      });
      this.details = res.data.map((x) => {
        return {
          id: null,
          product_id: x.id,
          name: x.name,
          paid: 0,
          plan_id: null,
          price: x.unit_price,
          comment: "",
          enabled: false,
          cost: x.unit_price,
        };
      });
    },
  },
};
</script>
<style scoped></style>
