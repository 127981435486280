/**
 * Author: Wilson Pilco Nuñez
 * Email: wilsonaux1@gmail.com
 * Created at: 2023-08-18 17:34
 */
import http from "src/lt/utils/http.js";

export default class PlanService {
  static async getList(params) {
    return (await http.get("api/service/plan", { params })).data;
  }

  static async get(id) {
    return (await http.get(`api/service/plan/${id}`)).data;
  }

  static async save(reg) {
    if (reg.id === undefined || reg.id === null) {
      return (await http.post("api/service/plan", reg)).data;
    } else {
      return (await http.put(`api/service/plan/${reg.id}`, reg)).data;
    }
  }

  static async delete(id) {
    return (await http.delete(`api/service/plan/${id}`)).data;
  }

  static async getDetails(params) {
    return (await http.get("api/service/plan-detail", { params })).data;
  }

  static async getDetail(id) {
    return (await http.get(`api/service/plan-detail/${id}`)).data;
  }

  static async saveDetail(reg) {
    if (reg.id === undefined || reg.id === null) {
      return (await http.post("api/service/plan-detail", reg)).data;
    } else {
      return (await http.put(`api/service/plan-detail/${reg.id}`, reg)).data;
    }
  }

  static async deleteDetail(id) {
    return (await http.delete(`api/service/plan-detail/${id}`)).data;
  }
}
