<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2023-08-23 15:34 -->
<!-- Description:  -->
<template>
  <form @submit.prevent="search()">
    <div class="mb-3">
      <label for="">Nombre, DNI o Num. Celular</label>
      <input type="text" required class="form-control" v-model="query" />
    </div>
    <button class="btn btn-primary mb-3" type="submit">Buscar</button>
    <TableViewSet
      :getter="PlanService.getList"
      :params="{ search: query }"
      :loadOnMounted="false"
      ref="elTableViewSet"
    >
      <template #default="{ list }">
        <tr v-for="item in list" :key="item.id">
          <td>{{ item.operation_at }}</td>
          <td>{{ item.client_name }}</td>
          <td>
            <button class="btn btn-primary" @click="$emit('see', item)">
              <i class="fas fa-search"></i>
            </button>
          </td>
        </tr>
      </template>
    </TableViewSet>
  </form>
</template>
<script>
import PlanService from "./PlanService.js";
import TableViewSet from "src/components/TableViewSet.vue";
// const rules = {}
export default {
  name: "SearchForm",
  components: {
    TableViewSet,
  },

  // directives

  props: {
    disabled: { default: false },
  },

  data: () => ({
    query: "",
    PlanService,
    sending: false,
  }),

  computed: {
    errors() {
      return false;
    },
  },

  // watch: {},

  mounted() {
    //
  },

  methods: {
    save() {
      this.sending = true;
      this.$emit("submitted");
    },
    reset() {
      this.sending = false;
    },
    setValue() {},
    async search() {
      this.$refs.elTableViewSet.reload();
    },
  },
};
</script>
<style scoped></style>
